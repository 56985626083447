import React, { PropsWithChildren, useEffect } from "react";

import { ThemeProvider } from "@loyaltylogistix/component-library";
import { useSetRecoilState } from "recoil";

import Head from "Components/Head";
import ToastContainer from "Components/ToastContainer";

import MSALProvider from "./MSALProvider";
import AxiosProvider from "./AxiosProvider";
import LibraryTranslationProvider from "./LibraryTranslationsProvider";

import useInitialAppData from "../utils/useInitialAppData";
import { CorePlatformConfig } from "../atoms";

/**
 * Base config provider for loyaltylogistix platform - sets up some global providers and instances such as msal, axios, and theme etc...
 */
export default function LLPConfigProvider({ children }: PropsWithChildren) {
    const { isLoading, theme, config } = useInitialAppData();
    const setConfig = useSetRecoilState(CorePlatformConfig);

    useEffect(() => {
        if (!isLoading && config) {
            setConfig(config);
        }
    }, [config, isLoading]);

    return !isLoading ? (
        <Head
            title={config.title}
            favicon={config.favicon}
        >
            <ThemeProvider theme={theme}>
                <MSALProvider config={config}>
                    <AxiosProvider config={config}>
                        <LibraryTranslationProvider>
                            <ToastContainer time={3000}>{children}</ToastContainer>
                        </LibraryTranslationProvider>
                    </AxiosProvider>
                </MSALProvider>
            </ThemeProvider>
        </Head>
    ) : null;
}
