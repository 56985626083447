const DefaultAppConfig = {
    api: {
        url: "https://llp-gateway-api-testing.azurewebsites.net/",
        b2c: {
            SIGN_UP_SIGN_IN_POLICY: "B2C_1_susi_staff_app",
            FORGOT_PASSWORD_POLICY: "B2C_1_pwd_reset",
            EDIT_PROFILE_POLICY: "B2C_1_editprofile_staff_app ",
            TENANT_NAME: "llauth",
            CLIENT_ID: "cc6c7bed-b952-4520-8932-b45c429d3884",
            SCOPES: {
                OPENID: "openid",
                OFFLINE_ACCESS: "offline_access",
                PROFILE: "profile",
            },
        },
        brands: [
            {
                id: "13ee6d49-cd01-4d99-7f9c-08daa1361e58",
                name: "Hyundai",
            },
            {
                id: "c646bf24-d8ee-416b-7f9b-08daa1361e58",
                name: "Isuzu",
            },
            {
                id: "13ee6d49-cd01-4d99-7f9c-08daa1361e58",
                name: "Renault",
            },
            {
                id: "24eb9d5c-7fba-4c16-7f9d-08daa1361e58",
                name: "Kia",
            },
            {
                id: "2c455236-869b-4d62-26e8-08da7085b715",
                name: "Jaguar",
            },
            {
                id: "66709ab5-85b0-4cd4-26e9-08da7085b715",
                name: "Land Rover",
            },
        ],
    },
    title: "Staging",
    favicon: "/icons/renault/logo.svg",
    beePluginUID: "username",
};

export default DefaultAppConfig;
