export const moduleWrapper = theme => ({
    gridColumn:"2 / -1",
    gridRow:"1 / 2",
    height:"var(--headerDimensions, 55px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.colors.white,
    borderBottom:"1px solid",
    borderColor: theme.colors.grey[400],
    h1: {
        fontSize: theme.fonts.xl,
        margin: "0 0 0 25px",
        padding: 0,
    }
})

export default moduleWrapper;