import { DefaultValue, selector } from "recoil";
import { CorePlatformVehicleAgeGroups } from "../core/atoms";

export const PlatformVehicleAgeGroups = selector({
    key: "public::platform-vehicle-age-groups",
    get: ({ get }) => get(CorePlatformVehicleAgeGroups),
});

export const PlatformSelectedVehicleAgeGroups = selector({
    key: "public::platform-selected-vehicle-age-groups",
    get: ({ get }) => get(CorePlatformVehicleAgeGroups).selected,
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformVehicleAgeGroups, (prev) => ({ ...prev, selected: [] }));
            return;
        }
        set(CorePlatformVehicleAgeGroups, (prev) => ({ ...prev, selected: newValue }));
    },
});
