/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@loyaltylogistix/component-library/Inputs";

import { buttonContainerStyles, wrapperStyles } from "./FilterContent.styles";

export interface FilterContentProps {
    children: ReactNode;

    className?: string;

    onApplyClick?: () => void;
    applyChildren?: ReactNode;

    onCancelClick?: () => void;
    cancelChildren?: ReactNode;
}

export type FilterContentPublicProps = Pick<FilterContentProps, "onApplyClick" | "onCancelClick">;

export function FilterContent({
    children,

    className,

    onApplyClick,
    applyChildren,

    onCancelClick,
    cancelChildren,
}: FilterContentProps) {
    const { t } = useTranslation("filters");

    return (
        <div
            css={wrapperStyles}
            className={className}
        >
            {children}
            {onApplyClick || onCancelClick ? (
                <div css={buttonContainerStyles}>
                    {onCancelClick ? (
                        <Button onClick={onCancelClick}>{cancelChildren || t("cncl")}</Button>
                    ) : null}
                    {onApplyClick ? (
                        <Button
                            variant="bold"
                            onClick={onApplyClick}
                        >
                            {applyChildren || t("aply")}
                        </Button>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
