import {
    AuthenticationResult,
    EventType,
    LogLevel,
    PublicClientApplication,
} from "@azure/msal-browser";
import { PlatformConfig } from "../types/Platform.types";

export type B2C = PlatformConfig["api"]["b2c"];

export const currentUrl = window.location.href;
export const linkArray = currentUrl.split("/");
export const redirectUri = `${linkArray[0]}//${linkArray[2]}/`;

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;
    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;
    return msie || msie11;
};

export interface IProtectedResources {
    loginApi: {
        endpoint: string;
        scopes: string[];
    };
}

export function protectedResources({ TENANT_NAME }: B2C) {
    return {
        loginApi: {
            endpoint: "https://llp-gateway-api-testing.azurewebsites.net",
            scopes: [`https://${TENANT_NAME}.onmicrosoft.com/api/access_as_user`],
        },
    };
}

export interface ILoginRequest {
    scopes: string[];
    extraScopesToConsent: string[];
}

export function loginRequest({ TENANT_NAME }: B2C) {
    return {
        scopes: ["openid", "offline_access"],
        extraScopesToConsent: [`https://${TENANT_NAME}.onmicrosoft.com/api/access_as_user`],
        state: window.location.pathname,
    };
}

export function getConstants({
    SIGN_UP_SIGN_IN_POLICY,
    FORGOT_PASSWORD_POLICY,
    EDIT_PROFILE_POLICY,
    TENANT_NAME,
    CLIENT_ID,
    SCOPES = {
        OPENID: "openid",
        OFFLINE_ACCESS: "offline_access",
        PROFILE: "profile",
    },
}: B2C) {
    return {
        SIGN_UP_SIGN_IN_POLICY,
        FORGOT_PASSWORD_POLICY,
        EDIT_PROFILE_POLICY,
        TENANT_NAME,
        CLIENT_ID,
        SCOPES,
        REQUESTS: {
            LOGIN: {
                scopes: [SCOPES.OPENID, SCOPES.PROFILE],
            },
            EMAIL: {
                scopes: [],
            },
            REFRESH_TOKEN: {
                scopes: [CLIENT_ID],
            },
        },
    };
}

export function getPolicies({
    SIGN_UP_SIGN_IN_POLICY,
    FORGOT_PASSWORD_POLICY,
    EDIT_PROFILE_POLICY,
    TENANT_NAME,
}: ReturnType<typeof getConstants>) {
    return {
        names: {
            signUpSignIn: SIGN_UP_SIGN_IN_POLICY,
            forgotPassword: FORGOT_PASSWORD_POLICY,
            editProfile: EDIT_PROFILE_POLICY,
        },
        authorities: {
            signUpSignIn: {
                authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/${SIGN_UP_SIGN_IN_POLICY}`,
            },
            forgotPassword: {
                authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/${FORGOT_PASSWORD_POLICY}`,
            },
            editProfile: {
                authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/${EDIT_PROFILE_POLICY}`,
            },
        },
        authorityDomain: `${TENANT_NAME}.b2clogin.com`,
    };
}

export function getMsalConfig(b2c: B2C) {
    const constants = getConstants(b2c);
    const policies = getPolicies(constants);

    return {
        auth: {
            clientId: constants.CLIENT_ID,
            authority: policies.authorities.signUpSignIn.authority,
            knownAuthorities: [policies.authorityDomain],
            redirectUri,
            postLogoutRedirectUri: redirectUri,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: "localStorage",
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Error,
                loggerCallback: (level: LogLevel, message: string, containsPii: unknown) => {
                    if (containsPii) return;
                    // eslint-disable-next-line default-case
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                    }
                },
            },
            tokenRenewalOffsetSeconds: 300,
        },
    };
}

export function msalFactory(config: PlatformConfig) {
    const msalConfig = getMsalConfig(config.api.b2c);
    const instance = new PublicClientApplication(msalConfig);

    instance.addEventCallback((event) => {
        if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            (event.payload as AuthenticationResult)
        ) {
            const { account } = event.payload as AuthenticationResult;
            instance.setActiveAccount(account);
        }
    });

    return instance;
}
