const mx600 = "@media screen and (max-width: 675px)";

export const topMenu = (open, navigation, navigationElm) => (theme) => ({
    display: navigation && Object.values(navigation).length > 0 ? "flex" : "none",
    minHeight: "42px",
    position: "sticky",
    gridColumn: "2 / -1",
    gridRow: "2 / 3",
    top: 0,
    zIndex: 10,
    div: {
        display: "flex",
        flexFlow: "row nowrap",
        background: "white",
        borderBottom: "1px solid",
        borderColor: theme.colors.grey[400],
        width: "100%",

        [mx600]: {
            position: "absolute",
            maxHeight: "42px",
            overflow: "hidden",
            transition: "250ms ease-in-out",
            ...(open && {
                width: "100%",
                maxHeight: `${(navigationElm?.current?.scrollHeight || 0) + 75}px`,
            }),
        },

        svg: {
            width: "min-content",
            display: "none",
            marginTop: "10px",
            marginRight: "10px",
            cursor: "pointer",
            [mx600]: {
                display: "block",
                fontSize: theme.fonts.xl,
                transform: `rotate(${open ? "180deg" : "0deg"})`,
            },
            transition: "100ms",
        },
        nav: {
            width: "100%",
            paddingLeft: "25px",
            margin: 0,
            backgroundColor: theme.colors.white,
            display: "flex",
            flexFlow: "row wrap",
            [mx600]: {
                flexFlow: "column nowrap",
            },
        },
        li: {
            display: "inline",
            listStyleType: "none",
            fontWeight: 400,
            fontSize: 13,
        },
        a: {
            paddingTop: "10px",
            paddingBottom: "7px",
            marginRight: "48px",
            color: theme.colors.ink[400],
            display: "inline-block",
            borderBottom: "2px solid transparent",
            textDecoration: "none",
            "&.active": {
                display: "unset",
                fontWeight: 500,

                borderColor: theme.colors.background,
                [mx600]: {
                    order: "-1",
                    borderColor: "transparent",
                },
            },
            [mx600]: {
                display: "flex",
                width: "fit-content",
                paddingBottom: "0px",
                marginBottom: "5px",
            },
        },
    },
});
