import React, { PropsWithChildren } from "react";

import LLPConfigProvider from "./core/providers/LLPConfigProvider";
import PackageProviders from "./core/providers/PackageProviders";
import PlatformConstants from "./core/providers/PlatformConstants";

export default function AppProvider({ children } : PropsWithChildren){
  return <PackageProviders>
    <LLPConfigProvider>
      <PlatformConstants>
        { children }
      </PlatformConstants>
    </LLPConfigProvider>
  </PackageProviders>
}