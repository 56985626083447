import { DefaultValue, selector } from "recoil";
import { CorePlatformModels } from "../core/atoms";

export const PlatformModels = selector({
  key:"public::platform-models",
  get: ({ get }) => get(CorePlatformModels)
})

export const PlatformSelectedModels = selector({
  key:"public::platform-selected-models",
  get: ({ get }) => get(CorePlatformModels).selected,
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue){
      set(CorePlatformModels, (prev) => ({...prev, selected: []}));
      return;
    }
    set(CorePlatformModels, (prev) => ({...prev, selected: newValue }));
  }
})