export const pageWrapper = {
    gridColumn: "2 / -1",
    gridRow: "3 / -1",
    width: "100%",
    height: "100%",
    overflow: "auto",
    overflowY: "scroll",
    "@media screen and (max-width: 675px)": {
        gridColumn: "1 / -1",
    },
    padding: "25px",
    display: "flex",
    flexFlow: "column nowrap",
};

export const contentHeader = {
    position: "sticky",
    top: 0,
    zIndex: "10",
};

export const scrollWrapper = {
    // background: "#F0F0F0",
    // height: "100%",
    // overflow: "auto",
};
