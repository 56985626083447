import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import {
    lastFullMonth,
    lastFullQuarter,
    lastTwelveMonths,
    lastTwelveMonthsFromLastMonth,
    monthToDate,
    quarterToDate,
    today,
    yearToDate,
} from "./DateActions";

const OneSecond = 1000;
const OneMinute = OneSecond * 60;
const OneHour = OneMinute * 60;

export type DateActionsKey =
    | "monthToDate"
    | "lastFullMonth"
    | "quarterToDate"
    | "lastFullQuarter"
    | "yearToDate"
    | "lastTwelveMonths";

export interface DateActionsProps {
    selectedFromDate: string;
    selectedToDate: string;
    /** The min date of the filter - must be in UTC format eg: YYYY-MM-DDTHH:mm:ss */
    minDate?: string;
    /** The max date of the filter - must be in UTC format eg: YYYY-MM-DDTHH:mm:ss */
    maxDate?: string;
    defaultAction?: DateActionsKey;
}

export function useDateActions({
    selectedFromDate,
    selectedToDate,
    minDate,
    maxDate,
    defaultAction = "lastFullQuarter",
}: DateActionsProps) {
    // By memoizing the calculated actions we skip having to do any work on demand, however we should make sure to update the date
    // every half hour to ensure we don't get stuck on a date that is no longer today.
    const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
    useEffect(() => {
        const updateDate = () => setDate(dayjs().format("YYYY-MM-DD"));
        const interval = setInterval(updateDate, OneHour / 2);
        return () => clearInterval(interval);
    }, []);

    const calculatedActions = useMemo(
        () =>
            ({
                today: today(),
                monthToDate: monthToDate(),
                lastFullMonth: lastFullMonth(),
                quarterToDate: quarterToDate(),
                lastFullQuarter: lastFullQuarter(),
                yearToDate: yearToDate(),
                lastTwelveMonths: lastTwelveMonths(),
                lastTwelveMonthsFromLastMonth: lastTwelveMonthsFromLastMonth(),
            } as const),
        [date]
    );

    const [selectedAction, setSelectedAction] = useState<keyof typeof calculatedActions>();
    const onActionSelected = (action?: keyof typeof calculatedActions) => setSelectedAction(action);

    const isDisabled = (action: keyof typeof calculatedActions) => {
        const [from, to] = calculatedActions[action];
        const fromDate = dayjs(from);
        const toDate = dayjs(to);

        if (minDate && fromDate.isBefore(minDate, "date")) {
            return true;
        }

        if (maxDate && toDate.isAfter(maxDate, "date")) {
            return true;
        }

        return false;
    };

    const selectedDateMatchesAction = (action: keyof typeof calculatedActions) => {
        const [from, to] = calculatedActions[action];
        return (
            dayjs(selectedFromDate).isSame(from, "date") && dayjs(selectedToDate).isSame(to, "date")
        );
    };

    const getSelectedAndIsHighlighted = (action: keyof typeof calculatedActions) => ({
        selected: selectedDateMatchesAction(action),
        isHighlighted: selectedAction === action,
        isDisabled: isDisabled(action),
    });

    const actions = {
        monthToDate: {
            label: "Month to Date",
            ...getSelectedAndIsHighlighted("monthToDate"),
            handler: () => calculatedActions.monthToDate,
        },
        lastFullMonth: {
            label: "Last Full Month",
            ...getSelectedAndIsHighlighted("lastFullMonth"),
            handler: () => calculatedActions.lastFullMonth,
        },
        quarterToDate: {
            label: "Quarter to Date",
            ...getSelectedAndIsHighlighted("quarterToDate"),
            handler: () => calculatedActions.quarterToDate,
        },
        lastFullQuarter: {
            label: "Last Full Quarter",
            ...getSelectedAndIsHighlighted("lastFullQuarter"),
            handler: () => calculatedActions.lastFullQuarter,
        },
        yearToDate: {
            label: "Year to Date",
            ...getSelectedAndIsHighlighted("yearToDate"),
            handler: () => calculatedActions.yearToDate,
        },
        lastTwelveMonths: {
            label: "Last 12 Months",
            ...getSelectedAndIsHighlighted("lastTwelveMonths"),
            handler: () => calculatedActions.lastTwelveMonths,
        },
    };

    return {
        actions,
        onActionSelected,
        defaultAction,
    };
}

export type DateActions = ReturnType<typeof useDateActions>["actions"];
