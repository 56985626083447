import { atom } from "recoil";

export interface PlatformAvailableVehicleAgeGroups {
    isLoading: boolean;
    available: { groupLabel: string; id: string }[];
    selected: string[];
}

export const CorePlatformVehicleAgeGroups = atom<PlatformAvailableVehicleAgeGroups>({
    key: "core::platform-vehicle-age-groups",
    default: {
        isLoading: true,
        available: [],
        selected: [],
    },
});
