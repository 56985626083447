const fallbackDefaults = {};

export async function GetDevDefaults(){
  return fetch("/dev-settings.json")
    .then( res => res.json())
    .then( data => ({...fallbackDefaults, ...data}))
    .catch( () => fallbackDefaults)
}

export type AppDomains = Record<string, { theme: string, config: string }>;

export async function GetDomains(){
  try{
    const domainData = JSON.parse(localStorage.getItem("llplatform-domains") || "")
    if (!domainData?.localhost) throw new Error("Invalid config");
    return domainData as AppDomains;
  }catch( err){
    const domains = await fetch("/global/domains.json")
      .then( res => res.json())
      .then( data => {
        window.localStorage.setItem("llplatform-domains",JSON.stringify(data));
        return data as AppDomains
      })
      .catch( () => (null))
    return domains;
  }
}

export async function FetchInitialAppConfigUris(domain: string){
  const defaultDomainConfig = process.env.NODE_ENV !== "development" ? {} : await GetDevDefaults();
  const domainConfigs = await GetDomains();

  const { theme: themeUri = "failed", config: configUri = "failed" } = domainConfigs?.[domain] || defaultDomainConfig;
  return { themeUri, configUri };
}