/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import {
    EventHandler,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    useId,
    useLayoutEffect,
    useRef,
} from "react";
import { useTranslation } from "react-i18next";

import { container, dropdownControlsContainer, dropdownStyles } from "./FilterDropdown.styles";

export interface FilterDropdownProps {
    controlChildren: ReactNode;

    onDropdownOpen: MouseEventHandler<HTMLElement>;
    onDropdownClose: () => void;
    open: boolean;
    disabled?: boolean;
    className?: string;

    children: ReactNode;
}

export type FilterDropdownPublicProps = Pick<
    FilterDropdownProps,
    "open" | "onDropdownOpen" | "onDropdownClose" | "disabled"
>;

const dropdownMarginInPx = 20;

export function FilterDropdown({
    controlChildren,
    onDropdownOpen,
    onDropdownClose,
    open,
    disabled,
    className,

    children,
}: FilterDropdownProps) {
    const { t } = useTranslation("common");
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const dropdownButtonRef = useRef<HTMLButtonElement>(null);

    const stopPropagation: EventHandler<MouseEvent> = (event) => event.stopPropagation();

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        if (!open) {
            onDropdownOpen(event);
            return;
        }
        onDropdownClose();
    };

    useLayoutEffect(() => {
        const dropdownElement = dropdownContainerRef.current;
        const buttonElement = dropdownButtonRef.current;

        function calculatePosition() {
            if (!dropdownElement || !buttonElement) {
                return;
            }
            const buttonBoundingRect = buttonElement.getBoundingClientRect();
            const dropdownBoundingRect = dropdownElement.getBoundingClientRect();

            const buttonLeft = buttonBoundingRect.left;
            const buttonRight = buttonBoundingRect.right;

            const dropdownWidth = dropdownBoundingRect.width;

            const windowWidth = window.innerWidth;
            const overflowsRight = buttonLeft + dropdownWidth > windowWidth - dropdownMarginInPx;
            const overflowsLeft = buttonRight - dropdownWidth < dropdownMarginInPx;

            if (overflowsRight && overflowsLeft) {
                dropdownElement.setAttribute("data-cover", "true");
                return;
            }
            dropdownElement.removeAttribute("data-cover");
            dropdownElement.setAttribute("data-position", overflowsRight ? "right" : "left");
        }

        if (dropdownElement && buttonElement) {
            calculatePosition();
            window.addEventListener("resize", calculatePosition);
            return () => window.removeEventListener("resize", calculatePosition);
        }

        return () => null;
    }, [open]);

    const id = useId();

    return (
        <div
            css={container}
            className={className}
        >
            <button
                css={dropdownControlsContainer}
                ref={dropdownButtonRef}
                aria-haspopup="dialog"
                aria-controls={id}
                aria-expanded={!disabled && open}
                type="button"
                onClick={!disabled ? handleButtonClick : () => null}
                aria-disabled={disabled}
                disabled={disabled}
                title={disabled ? t("filter-disabled") || "" : undefined}
            >
                {controlChildren}
            </button>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                ref={dropdownContainerRef}
                css={dropdownStyles}
                id={id}
                aria-hidden={!open || disabled}
                onClick={stopPropagation}
            >
                {children}
            </div>
        </div>
    );
}
