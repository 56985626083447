import { DefaultValue, atom, selector } from "recoil";

import {
    ReportingRetailerAreaModel,
    ReportingRetailerGroupModel,
    ReportingRetailerSiteModel,
} from "@loyaltylogistix/api-client";

export interface CampaignsDealersAtom {
    isAreasLoading: boolean;
    isGroupsLoading: boolean;
    areas: ReportingRetailerAreaModel[];
    groups: ReportingRetailerGroupModel[];
    selected: ReportingRetailerSiteModel[];
}

export type AvailableDealerType<TType extends "areas" | "groups"> = {
    isLoading: boolean;
    available: CampaignsDealersAtom[TType];
};

/**
 * @protected This atom should not be accessed directly. Use CampaignsDealers, CampaignsDealerAreas, CampaignsDealerGroups, or CampaignsSelectedDealers selectors instead.
 */
export const CoreCampaignsDealers = atom<CampaignsDealersAtom>({
    key: "core::campaigns-dealers",
    default: {
        isAreasLoading: true,
        isGroupsLoading: true,
        areas: [],
        groups: [],
        selected: [],
    },
});

/**
 * @protected This atom should not be accessed directly. Use CampaignsDealers, CampaignsDealerAreas, CampaignsDealerGroups, or CampaignsSelectedDealers selectors instead.
 */
export const CoreCampaignsDealerAreas = selector<AvailableDealerType<"areas">>({
    key: "core::campaigns-dealer-areas",
    get: ({ get }) => {
        const { isAreasLoading: isLoading, areas: available } = get(CoreCampaignsDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CoreCampaignsDealers, (prev) => ({ ...prev, isAreasLoading: true, areas: [] }));
            return;
        }
        set(CoreCampaignsDealers, (prev) => ({
            ...prev,
            isAreasLoading: newValue.isLoading,
            areas: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use CampaignsDealers, CampaignsDealerAreas, CampaignsDealerGroups, or CampaignsSelectedDealers selectors instead.
 */
export const CoreCampaignsDealerGroups = selector<AvailableDealerType<"areas">>({
    key: "core::Campaigns-dealer-groups",
    get: ({ get }) => {
        const { isGroupsLoading: isLoading, groups: available } = get(CoreCampaignsDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CoreCampaignsDealers, (prev) => ({ ...prev, isGroupsLoading: true, groups: [] }));
            return;
        }
        set(CoreCampaignsDealers, (prev) => ({
            ...prev,
            isGroupsLoading: newValue.isLoading,
            groups: newValue.available,
        }));
    },
});
