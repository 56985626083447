import { selector } from "recoil";
import { PermissionModel } from "@loyaltylogistix/api-client";
import { CorePlatformUserPermissions } from "../core/atoms";

export const PlatformUserPermissions = selector<PermissionModel[]>({
  key:"public::platform-user-permissions",
  get: ({get}) => get(CorePlatformUserPermissions)
})

export const PlatformUserPermissionIds = selector<number[]>({
  key:"public::platform-user-permission-ids",
  get: ({get}) => get(PlatformUserPermissions).map(({id}) => id as number)
})