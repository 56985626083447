import React, { ReactNode } from "react";
import { checkIfPermitted } from "global/tsHelpers";
import { useRecoilValue } from "recoil";
import { PlatformUserPermissions } from "state/global";

export interface AccessControlProps {
    children: ReactNode;
    permissionsIds: number | number[];
}
/**
 * A wrapper component that hides parts of the UI according to the user's permissions
 * @children the component which is checked against the user's permissions so it can
 * be displayed or hidden
 * @permissionsIds the permission/s id/s which displays the component if it exists in the user' permissions
 * or hides it if not
 * @returns the wrapped component if the permission exist or null if not
 */
export const AccessControl = ({ children, permissionsIds }: AccessControlProps) => {
    const permissions = useRecoilValue(PlatformUserPermissions)
    return checkIfPermitted(permissions, permissionsIds) ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>{children}</React.Fragment>
    ) : null;
};
