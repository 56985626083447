/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ComboBox } from "@loyaltylogistix/component-library/Inputs";
import { useRoutes } from "hooks/useRoutes/useRoutes";
import { AccessControl } from "Components/AccessControl/AccessControl";
import { redirectUri } from "state/global/core/utils/msalFactory";
import { PlatformActiveMarket, PlatformMarkets } from "state/global";
import * as styles from "./HeaderUtilities.styles";

function HeaderUtilities() {
    const { instance } = useMsal();
    const history = useHistory();

    const [, ROUTES] = useRoutes();

    const { available, active } = useRecoilValue(PlatformMarkets);
    const setSelectedMarket = useSetRecoilState(PlatformActiveMarket);
    const marketSelectItems = available.map((market) => ({ value: market.id, label: market.name }));
    const handleMarketSelect = (event) => setSelectedMarket(available.find((market) => market.id === event.target.value));

    return (
        <nav
            aria-label="Global navigation"
            css={styles.headerUtilitiesWrapper}
        >
            {
                // the market select should be hidden when no markets to select other than current :)
                marketSelectItems.length > 1 ? (
                    <ComboBox
                        name="active-market"
                        options={marketSelectItems}
                        value={active.id}
                        isClearable={false}
                        onChange={handleMarketSelect}
                        hideLabel
                        hideMessage
                        size="xs"
                        selectClassName="marketSelectDropdown"
                    />
                ) : null
            }
            <AccessControl permissionsIds={ROUTES.SETTINGS.scoopedPermissions}>
                <li>
                    <FontAwesomeIcon
                        role="link"
                        aria-label="Settings"
                        tabIndex="0"
                        icon={faCog}
                        fixedWidth
                        onClick={() => history.push(ROUTES.SETTINGS.path)}
                    />
                </li>
            </AccessControl>

            <li>
                <FontAwesomeIcon
                    role="link"
                    aria-label="Settings"
                    tabIndex="0"
                    icon={faRightFromBracket}
                    fixedWidth
                    onClick={() => {
                        instance.logoutRedirect({
                            account: null,
                            postLogoutRedirectUri: redirectUri,
                            mainWindowRedirectUri: redirectUri,
                        });
                        // Clear the storage to remove the token and also the id of
                        // the user's account
                        localStorage.clear();
                    }}
                />
            </li>
        </nav>
    );
}

export default HeaderUtilities;
