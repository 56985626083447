import { FetchAppConfigFile, FetchAppThemeFile } from "./FetchConfigFiles";
import { FetchInitialAppConfigUris } from "./FetchConfigUris";

export async function FetchAppConfig(domain: string){
  const { themeUri, configUri } = await FetchInitialAppConfigUris(domain);
  const [theme, config] = await Promise.all([
    FetchAppThemeFile(themeUri),
    FetchAppConfigFile(configUri)
  ]);

  return { theme, config };
}