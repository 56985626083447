import React, { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";

const client = new QueryClient();

/**
 * Required package providers for the platform - must be mounted at the root of the application
 */
export default function PackageProviders( { children } : PropsWithChildren){
  return (
    <RecoilRoot>
      <QueryClientProvider client={client}>
        { children }
      </QueryClientProvider>
    </RecoilRoot>
  )
}