import React, { PropsWithChildren } from "react";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider as Msal } from "@azure/msal-react";

import { PlatformConfig } from "../types/Platform.types";
import { msalFactory } from "../utils/msalFactory";

export interface MSALProviderProps extends PropsWithChildren {
    config: PlatformConfig;
}

let msalInstance: PublicClientApplication;

export default function MSALProvider({ config, children }: MSALProviderProps) {
    if (!msalInstance) msalInstance = msalFactory(config);

    return <Msal instance={msalInstance}>{children}</Msal>;
}
