import { selector } from "recoil";

import { CorePlatformAvailableBrands, CorePlatformConfig } from "../core/atoms";

export const PlatformBrands = selector({
  key:"public::platform-brands",
  get: ({ get }) => {
    const active = get(CorePlatformConfig).api.brands[0];
    const { isLoading, available } = get(CorePlatformAvailableBrands)
    return { active, isLoading, available }
  }
})

export const PlatformActiveBrand = selector({
  key:"public::platform-active-brand",
  get: ({ get }) => get(CorePlatformConfig).api.brands[0]
})

export const PlatformAvailableBrands = selector({
  key:"public::platform-available-brands",
  get: ({ get }) => get(CorePlatformAvailableBrands)
})