import { CSSObject, Theme } from "@emotion/react";

export const container: CSSObject = {
    position: "relative",
    flexGrow: "1",
    minWidth: "250px",
    display: "flex",
    height: "100%",
};

export const dropdownStyles = (theme: Theme): CSSObject => ({
    position: "absolute",
    top: "calc(100% + 0.5rem)",
    minWidth: "100%",
    width: "max-content",
    maxWidth: "60vw",
    padding: "1rem",
    background: theme.future.palette.greys[0],
    height: "fit-content",
    zIndex: 10000,
    borderRadius: theme.future.styles.main.borderRadius,
    boxShadow: theme.future.shadows.pageSurround,

    transition: "250ms",

    "&[data-position='left']": {
        left: 0,
    },

    "&[data-position='right']": {
        right: 0,
    },

    "&[data-cover='true', @media screen and (max-width: 900px)]": {
        position: "fixed",
        inset: 0,
        height: "100vh",
        width: "100vw",
        maxWidth: "unset",
        borderRadius: "0px",
        overflow: "auto",
    },

    "&[aria-hidden='true']": {
        opacity: 0,
        pointerEvents: "none",
        transform: "translateY(-1rem)",
        zIndex: "-1",
    },
});

export const dropdownControlsContainer = (theme: Theme): CSSObject => ({
    display: "flex",
    alignItems: "center",
    background: theme.future.palette.greys[0],
    padding: "0.5rem",
    border: "none",
    borderRadius: theme.future.styles.main.borderRadius,
    boxShadow: theme.future.shadows.pageSurround,
    width: "100%",
    height: "100%",
    gap: "1em",
    ...theme.future.typography.text.xs,
    "&[aria-disabled]": {
        opacity: 0.8,
        color: "var(-internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)))",
    },
});
