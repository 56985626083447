/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ModuleHeader from "../ModuleHeader/ModuleHeader";

import ModuleNavigation from "../ModuleNavigation/ModuleNavigation";

import * as styles from "./PageTemplate.styles";

function PageTemplate({ children, title, navigation }) {
    const page = title.toLowerCase();
    return (
        <Fragment>
            <ModuleHeader title={title} />
            <ModuleNavigation
                navigation={navigation}
                pageName={page}
            />
            <div css={styles.pageWrapper}>{children}</div>
        </Fragment>
    );
}

PageTemplate.propTypes = {
    navigation: PropTypes.arrayOf(
        PropTypes.shape({
            scoopedPermissions: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.number),
                PropTypes.number,
            ]),
            route: PropTypes.string,
            title: PropTypes.string,
        })
    ),
    title: PropTypes.string,
    children: PropTypes.node,
};

export default PageTemplate;
