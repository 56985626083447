import { useAccount, useMsal } from "@azure/msal-react";
import { redirectUri } from "state/global/core/utils/msalFactory";
import { UserModel } from "@loyaltylogistix/api-client";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { IdentityClient, UserClient } from "services/api.clients";

export default function useActiveAccount(onSuccess?: (user: UserModel) => void) {
    const { instance } = useMsal();
    const account = useAccount();
    const userId = account?.idTokenClaims?.extension_UserId as string;

    const queryClient = useQueryClient();

    const {
        mutate: b2cSignup,
        isLoading: b2cSignupPending,
        failureCount,
    } = useMutation({
        mutationFn: (id: string) => IdentityClient.identitySignupUserIdB2cPost(id),
        onMutate: (id: string) => {
            const user = queryClient.getQueryData(["activeUser", id]) as UserModel | undefined;
            queryClient.setQueryData(["activeUser", id], (oldData: UserModel | undefined) => ({
                ...oldData,
                isSignedUp: true,
            }));

            return user;
        },
        onError: (_, variables, context) => {
            queryClient.setQueryData(["activeUser", context as string], variables);
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries(["activeUser", variables]);
        },
        retry: 3,
    });

    const { status, data, error, isLoading } = useQuery(
        ["activeUser", userId],
        () =>
            UserClient.identityUsersIdGet(userId)
                .then((res) => res.data)
                .then((user) => {
                    if (!user.isSignedUp && !b2cSignupPending) {
                        b2cSignup(user.id || "");
                    }

                    return user;
                }),
        {
            enabled: !!userId,
            onSuccess: (user) => {
                if (onSuccess) {
                    onSuccess(user);
                }
                if (!user.isSignedUp && failureCount === 0 && !b2cSignupPending) {
                    b2cSignup(user.id || "");
                }
            },
            onError: (err) => {
                instance.logoutRedirect({
                    account: null,
                    postLogoutRedirectUri: redirectUri,
                });
                // Clear the storage to remove the token and also the id of
                // the user's account
                localStorage.clear();
            },
        }
    );

    return {
        status,
        error,
        user: data,
        isLoading,
    };
}
