import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { Loader } from "@loyaltylogistix/component-library";
import PropTypes from "prop-types";

import { handleRouteDisplay } from "hooks/useRoutes";
import { checkIfPermitted } from "global/tsHelpers";
import { useRecoilValue } from "recoil";
import { PlatformUserPermissions } from "state/global";

// Lazy load the unauthorized component whenever the user has no permissions to
// view a certain page
const Unauthorized = React.lazy(() => import("Components/Unauthorized/Unauthorized"));

export default function ProtectedRoute({ route }) {
    // Use the auth context to pass check if the user is permitted to access the route or not
    const permissions = useRecoilValue(PlatformUserPermissions);
    // The useIsAuthenticated hook returns a boolean indicating whether or not an account is signed in.
    // It optionally accepts an accountIdentifier object you can provide if you need to know whether or
    // not a specific account is signed in.
    const isAuthenticated = useIsAuthenticated();
    // Decide if the route is to be authorized or not
    // eslint-disable-next-line no-underscore-dangle
    const _route = checkIfPermitted(permissions, route.scoopedPermissions)
        ? route
        : {
            ...route,
            component: permissions?.length > 0 ? <Unauthorized /> : <Loader />,
        };
    return (
        <Route
            exact={route.exact}
            path={route.path}
            render={() => (isAuthenticated ? handleRouteDisplay(_route) : <Redirect to="/" />)}
        />
    );
}

ProtectedRoute.propTypes = {
    route: PropTypes.shape({
        path: PropTypes.string,
        exact: PropTypes.bool,
        scoopedPermissions: PropTypes.oneOfType(
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number)
        ),
    }),
};
