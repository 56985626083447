export const wrapperStyles = {
  display:"flex", 
  flexFlow:"column", 
  gap:"2rem",
  height:"100%",
  justifyContent:"space-between"
}

export const buttonContainerStyles = {
  display:"flex",
  gap:"1rem",
  "> *": {
    flex:"1"
  }
}