import { atom } from "recoil";

import { MarketModel } from "@loyaltylogistix/api-client";
import { localStorageEffect } from "state/effects/localStorageEfffect";

/**
 * @protected This atom should not be accessed directly. Use PlatformMarkets, or PlatformActiveMarket selectors instead.
 */
export const CorePlatformActiveMarket = atom<MarketModel>({
  key: "core::platform-active-market",
  default: { id:"" },
  effects:[ localStorageEffect("core::platform-active-market") ]
})

export interface PlatformAvailableMarkets{
  isLoading: boolean;
  available: MarketModel[];
}

/**
 * @protected This atom should not be accessed directly. Use PlatformMarkets, or PlatformActiveMarket selectors instead.
 */
export const CorePlatformAvailableMarkets = atom<PlatformAvailableMarkets>({
  key: "core::platform-available-markets",
  default: {
    isLoading: true,
    available: []
  }
})