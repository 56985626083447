import { useState } from "react";
import { useFilterDropdownProps } from "Components/Filters/common";

import { DateActions, DateActionsKey, useDateActions } from "./useDateActions";
import { DateFilterProps } from "../DateFilter";

export interface UseDateFilterProps {
    selectedFromDate?: string;
    selectedToDate?: string;
    onApplyClick: (fromDate: string, toDate: string) => void;
    /** The min date of the filter - must be in UTC format eg: YYYY-MM-DDTHH:mm:ss */
    minDate?: string;
    /** The max date of the filter - must be in UTC format eg: YYYY-MM-DDTHH:mm:ss */
    maxDate?: string;
    defaultAction?: DateActionsKey;
}

export function useDateFilterProps({
    selectedFromDate = "",
    selectedToDate = "",
    onApplyClick: applyClickHandler,
    minDate,
    maxDate,
    defaultAction = "lastFullQuarter",
}: UseDateFilterProps): DateFilterProps<DateActions> {
    const [filterFromDate, setFilterFromDate] = useState(selectedFromDate);
    const [filterToDate, setFilterToDate] = useState(selectedToDate);

    const { open, onDropdownOpen, onDropdownClose } = useFilterDropdownProps();

    const {
        onActionSelected,
        actions,
        defaultAction: internalDefaultAction,
    } = useDateActions({
        selectedFromDate,
        selectedToDate,
        minDate,
        maxDate,
        defaultAction,
    });

    const onApplyClick = () => {
        applyClickHandler(filterFromDate, filterToDate);
        onDropdownClose();
    };

    const onFromDateChange = (value: any) => setFilterFromDate(value);

    const onToDateChange = (value: any) => setFilterToDate(value);

    const onCancelClick = () => {
        const action = actions[internalDefaultAction];
        const [actionFromDate, actionToDate] = action.handler();

        setFilterFromDate(actionFromDate);
        setFilterToDate(actionToDate);

        onActionSelected(internalDefaultAction);
        applyClickHandler(actionFromDate, actionToDate);
        onDropdownClose();
    };

    return {
        open,
        onDropdownOpen,
        onDropdownClose,

        fromDate: filterFromDate,
        toDate: filterToDate,
        minDate,
        maxDate,
        selectedFromDate,
        selectedToDate,
        onFromDateChange,
        onToDateChange,

        actions,
        defaultAction: internalDefaultAction,
        onActionSelected,

        onApplyClick,
        onCancelClick,
    };
}
