import { lastFullQuarter } from "Components/Filters/Date/utils";
import { atom } from "recoil";

export interface PlatformSelectedDate {
  fromDate: string;
  toDate: string;
}

const [defaultFromDate, defaultToDate] = lastFullQuarter();

export const CorePlatformSelectedDate = atom<PlatformSelectedDate>({
  key:"core::platform-selected-date",
  default: { fromDate: defaultFromDate, toDate: defaultToDate },
})