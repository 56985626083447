import { atom } from "recoil";

export interface PlatformAvailableModels{
  isLoading: boolean;
  available: string[];
  selected: string[];
}

/**
 * @protected This atom should not be accessed directly. Use PlatformModels, or PlatformSelectedModels selectors instead.
 */
export const CorePlatformModels = atom<PlatformAvailableModels>({
  key: "core::platform-available-models",
  default: {
    isLoading: true,
    available: [],
    selected: []
  }
})