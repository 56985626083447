import { isValidElement } from "react";
import Joi from "joi";
import domainLocaleMap from "../domainLocaleMap.json";

// a helper function to return a unique name from a name
// i.e name => name[1]
export const createUniqueName = (itemsArray, checkItem) => {
    let uniqueName = "";
    let counter = 1;

    const nameFilter = (name) => (item) => item === name;

    while (counter !== 0) {
        uniqueName = `${checkItem} [${counter}]`;
        const duplicate = itemsArray.filter(nameFilter(uniqueName));
        if (duplicate?.length) {
            counter++;
            uniqueName = `${checkItem} [${counter}]`;
        } else {
            break;
        }
    }
    return uniqueName;
};

// Create a function to validate the form inputs upon the pre-defined
// scheme
export const validate = (data, schema) => {
    // Validate the inputs based on the scheme
    const result = schema.validate(data, { abortEarly: false });
    // Return null if the inputs are all valid
    if (!result.error) return null;
    // Create an object to save the error messages
    const errors = {};

    result.error.details.forEach((item) => {
        errors[item.path[0]] = item.message;
    });
    return errors;
};
// Create a function that decides if the form fields are valid
// upon the form data and schema passed on from the component
// so it can be submitted or not until it is valid
export const validateFields = (data, schema) =>
    Object.keys(validate(data, schema) || {}).length > 0;

// Create a function to check if the property passed on is
// valid and present in the scheme or not
export const validateProperty = (input, schema) => {
    let obj;
    if (input.type === "array") {
        obj = { [input?.name]: input?.value };
    } else {
        // Declare an object that contains the name and value of an input
        obj = { [input?.name]: input?.value?.toString().trim() };
    }
    // Extract the corresponding field in the scheme
    const fieldSchema = Joi.object({
        [input?.name]: schema.extract([input?.name]),
    });
    // Return null if the property is not present in the scheme
    if (fieldSchema.extract([input?.name]) === null) return null;
    // If the property is present in the scheme, check if it is
    // valid or not
    const { error } = fieldSchema.validate(obj);
    // Check if the error message is to be formatted or not
    const errorMessage = input?.label
        ? error?.details[0]?.message?.replace(input.name, input.label)
        : error?.details[0].message;
    return error ? errorMessage : null;
};
// Create a function to check if the field is valid or not
export const getFieldError = (input, schema, error) => {
    // Check if the scheme is present or not
    if (schema != null && schema[input.name] != null) {
        // If not, check if the property passed is valid or not
        const errorMessage = validateProperty(input, schema);
        // If yes, return an error message
        if (errorMessage) error[input.name] = errorMessage;
        // If not, remove the error message
        else delete error[input.name];
    }
    return error;
};
// Create a generic function that handles the change of an input
// and returns an error if it is not valid
export const handleChange = (input, schema, error, data) => {
    if (schema[input.name] != null) {
        const errorMessage = validateProperty(input, schema);
        if (errorMessage) error[input.name] = errorMessage;
        else delete error[input.name];
    }
    data[input.name] = input.value.toString().trim();
    if (input.value.toString().trim() === null || input.value.toString().trim() === "")
        input.value = "";
};
/**
    Create a function that handles the view of the search entry's 
    details and adds the recently viewed to the localStorage 
   * @param {object} target the data of each target in the list 
   */
export const viewSearchEntry = (target, history, path, type) => {
    // Navigate to the target details page (profile)
    history.push(`${path}/${type}/${target.id}`);
    // Create an array that updates the recently viewed targets
    let recentlyViewed = JSON.parse(localStorage.getItem(`${type}s`)) || [];

    // Filter the same entry out if existed
    recentlyViewed = recentlyViewed.filter((item) => item.id !== target.id);
    // Check if the list has already 5 elements so we shall remove the
    // last element before adding the new one
    if (recentlyViewed.length >= 5) {
        recentlyViewed.pop();
    }
    // Add the new entry in the array
    recentlyViewed.unshift(target);
    // Set the viewed target's object inside the recently viewed
    // array
    localStorage.setItem(`${type}s`, JSON.stringify(recentlyViewed));
};

// Create a function that composes multiple functions to implement
// complex functionalities
export const compose =
    (...args) =>
    (value) =>
        args.reduceRight((acc, fn) => fn(acc), value);

/** A recursive function that extracts valid elements from react refs
 * that contains an object, array single element etc...
 * @param { Ref } ref The react ref containing elements
 * @param { int } [depth=0] The starting depth of the recursion stack
 * @param { int } [maxDepth=5] The max depth of the recursion stack
 * @returns { Array.<Element> } An array of elements
 */
export function extractElementsFromRef(ref, depth = 0, maxDepth = 5) {
    // if the element is a valid dom/react element return just the
    // array containing that element
    if (isValidElement(ref) || ref instanceof HTMLElement) {
        return [ref];
    }
    // if the depth is greater than or equal to the max depth
    // - return an empty array to be flat mapped
    if (depth >= maxDepth) return [];

    if (Array.isArray(ref)) {
        // if the ref is an array, return the flatMap of the
        // return from recursive function of all object values
        return ref.flatMap((childRef) => extractElementsFromRef(childRef, depth++, maxDepth));
    }

    if (typeof ref === "object" && Boolean(ref)) {
        // if the ref is an object, return the flatMap of the
        // return from the recursive function of all object values
        return Object.values(ref).flatMap((childRef) =>
            extractElementsFromRef(childRef, depth++, maxDepth)
        );
    }
    // if not an object, not an array and not a valid element,
    // return an empty array;
    return [];
}

export const generateRangedIntFromString = (string, { prime, low = 0, high = 360 }) => {
    let base = parseInt(string, 18) % prime || 283;
    while (base < low || base > high) {
        const diff = Math.abs(base / 2);
        base = base > high ? base - diff : base + diff;
    }
    return base;
};

export const generateRandHslFromString = (
    string,
    { hueLow = 90, hueHigh = 240, litLow = 45, litHigh = 50, satLow = 50, satHigh = 90 } = {}
) => {
    const hue = generateRangedIntFromString(string, {
        prime: 1091,
        low: hueLow,
        high: hueHigh,
    });

    const sat = generateRangedIntFromString(string, {
        prime: 827,
        low: satLow,
        high: satHigh,
    });

    const lit = generateRangedIntFromString(string, {
        prime: 919,
        low: litLow,
        high: litHigh,
    });

    return `hsl(${hue}, ${sat}%, ${lit}%)`;
};

export const arraysAreEqual = (a, b, test = (at, bt) => at === bt) =>
    Array.isArray(a) &&
    Array.isArray(b) &&
    a?.length === b?.length &&
    a.every((val) => b.find((v) => test(val, v)));

export const onlyHasFields = (item, keys) => arraysAreEqual(Object.keys(item), keys);

export const removeFields = (item, keys) =>
    Object.fromEntries(Object.entries(item).filter(([k]) => !keys.includes(k)));

/**
 * Checks if the value passed is parsable or not
 * @param {any} string: the passed value to be tested if can be
 * parsed or not
 * @returns {boolean} returns turn if it can be parsed and false
 * otherwise
 */
export const isJsonParsable = (string) => {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
};

/**
 * Accessability helper to be used as spreadable props
 * on any component that has a clickable action
 * @param {function} handlerFn: function to be called on action
 */
export const actionable = (handlerFn) => ({
    onClick: handlerFn,
    onKeyDown: (event) => {
        if (event.key === "Enter" || event.keyCode === 13) {
            handlerFn(event);
        }
    },
});

/** A function that takes an object, the targeted key name and key value pairs object and returns
 * the object with the values changes inside the target
 * @param { parent } object for the parent which contains the value needed to be changed
 * @param { target }  a string holds the name of the key inside the parent which will be needed to change a value in it
 * @param { changingPairs } an object contains key value pairs of the needed elements eg.{key:'age', value: 10}
 */
export const changeItemInObject = (parent, target, changingPairs) => ({
    ...parent,
    [target]: { ...parent[target], ...changingPairs },
});

export const queryStateToAsyncState = (status) => {
    const toAsyncState = {
        idle: "loading",
        loading: "loading",
        success: "hasValue",
        error: "hasError",
    };
    return toAsyncState?.[status] || toAsyncState.loading;
};
/**
 * Flattens the object passed and move all nested properties into the
 * parent object
 * @param {Object} obj the nested object that should be flattened
 * @param {array} excludees the keys to be excluded from the flatten process
 * @returns the flattened version of the passed object as key/value pairs
 */
export const toFlatKeyArray = (obj, excludees = []) =>
    Object.entries(obj).reduce(
        (prev, [key, value]) =>
            typeof value === "object" && !Array.isArray(value) && !excludees.includes(key)
                ? [...prev, ...toFlatKeyArray(value, excludees)]
                : [...prev, [key, value]],
        []
    );

export const toFlatObj = (obj, excludees = []) =>
    Object.fromEntries(toFlatKeyArray(obj, excludees));

/* convert value to { label, value } */
export function singleValueToSelectType(options = [], value = "") {
    return options?.find((option) => option?.value === value);
}

/* convert array of or single value to ArrayOf or single {label, value} */
export function toSelectValueType(options, values) {
    return Array.isArray(values)
        ? values.map((value) => singleValueToSelectType(options, value))
        : singleValueToSelectType(options, values);
}

export function selectToValueType(selected) {
    return Array.isArray(selected) ? selected.map(({ value }) => value) : selected?.value || null;
}

export function getMilliseconds(hours = 0, minutes = 0, seconds = 0) {
    const milliseconds = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
    return milliseconds;
}

/**
 * The function is open for extension as there can be other cases to be covered
 * @param {string} str: the string (camel case - pascal case) to be converted
 * @returns returns a string separated by a space
 */
export const stringConversion = (str) => str.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");

/** a helper function to get the correct locale name depending on the domain/language to be used in toLocaleDateString */
export const getDateLocaleFormat = () => {
    const getLocale = () =>
        typeof window !== "undefined" ? domainLocaleMap[window.location.host] : "en";

    // Create an array of the available locales in the system
    const locales = new Map([
        ["en", "en-GB"],
        ["da", "da-DK"],
        ["no", "nb-NO"],
        ["sv", "sv-SE"],
    ]);

    return locales.get(getLocale());
};
/**
 * downloads any file type depends in the UI
 * @param {any} data // the data to be downloaded
 * @param {string} type // the file type to be downloaded
 * @param {string} fileName // the name of the downloaded file
 */
export const downloadFileFromData = (data, type, fileName) => {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName; // Specify the filename for the downloaded CSS file
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

/**
 * Handles some cases where we want to split a PascalCase or camelCase into
 * separate words
 * @param {string} targetString
 * @returns a converted string
 */
export const splitString = (targetString = "") =>
    targetString
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, "$1 $2")
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase())
        // Remove any white space left around the word
        .trim();

const defaultExports = {
    validate,
    validateProperty,
    validateFields,
    getFieldError,
    handleChange,
    createUniqueName,
    viewSearchEntry,
    compose,
    extractElementsFromRef,
    generateRangedIntFromString,
    generateRandHslFromString,
    arraysAreEqual,
    onlyHasFields,
    removeFields,
    isJsonParsable,
    actionable,
    changeItemInObject,
    toFlatObj,
    toFlatKeyArray,
    stringConversion,
    getDateLocaleFormat,
    splitString,
    downloadFileFromData,
};

export default defaultExports;
