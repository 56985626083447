import { MouseEventHandler, useRef, useState } from "react";

export function useFilterDropdownProps(){
  
  const triggerRef = useRef<HTMLElement>();
  const [open, setOpen] = useState(false);

  const onDropdownOpen: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    setOpen(true);
    triggerRef.current = event.currentTarget;
  }

  const onDropdownClose = () => {
    setOpen(false);
    triggerRef.current?.focus();
  }

  return {
    open,
    onDropdownOpen,
    onDropdownClose
  }

}