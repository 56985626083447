import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import { GlobalStyles } from "@loyaltylogistix/component-library";

import AppProvider from "state/global/AppProvider";

import App from "./App";

function AppWrapper() {
  return (
    <AppProvider>
      <GlobalStyles />
      <App />
    </AppProvider>
  );
}
export default AppWrapper;
