import { atom, selector } from "recoil";
import { BrandModel } from "@loyaltylogistix/api-client";

import { CorePlatformConfig } from "./Core.PlatformConfig.atom";

/**
 * @protected This selector should not be accessed directly. Use PlatformBrands, PlatformActiveBrand, or PlatformAvailableBrands selectors instead.
 */
export const CorePlatformActiveBrand = selector({
  key: "core::platform-active-brand",
  get: ({get}) => get(CorePlatformConfig).api.brands[0]
});

export interface PlatformAvailableBrandsAtom{
  isLoading: boolean;
  available: BrandModel[];
}

/**
 * @protected This selector should not be accessed directly. Use PlatformBrands, PlatformActiveBrand, or PlatformAvailableBrands selectors instead.
 */
export const CorePlatformAvailableBrands = atom<PlatformAvailableBrandsAtom>({
  key: "core::platform-available-brands",
  default: {
    isLoading: true,
    available: []
  }
})