
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { LibraryTranslationProvider as CompLibTranslations } from "@loyaltylogistix/component-library/_core";

export default function LibraryTranslationProvider({ children } : PropsWithChildren){

  const { t } = useTranslation("library");

  const dateInputTranslations = {
      defaultPlaceholder: t("dateInput.placeholder"),
      format: t("dateInput.format"),
      longFormat: t("dateInput.longFormat"),
      daysOfWeek:{
          monday: t("dateInput.monday"),
          tuesday: t("dateInput.tuesday"),
          wednesday: t("dateInput.wednesday"),
          thursday: t("dateInput.thursday"),
          friday: t("dateInput.friday"),
          saturday: t("dateInput.saturday"),
          sunday: t("dateInput.sunday")
      },
      monthsOfYear:{
          january: t("monthsOfYear.january"),
          february: t("monthsOfYear.february"),
          march: t("monthsOfYear.march"),
          april: t("monthsOfYear.april"),
          may: t("monthsOfYear.may"),
          june: t("monthsOfYear.june"),
          july: t("monthsOfYear.july"),
          august: t("monthsOfYear.august"),
          september: t("monthsOfYear.september"),
          october: t("monthsOfYear.October"),
          november: t("monthsOfYear.november"),
          december: t("monthsOfYear.december")
      },
      selectedDate: t("dateInput.selectedDate"),
      activeDate: t("dateInput.activeDate"),
      noneSelected: t("dateInput.noneSelected"),
      invalidDate: t("dateInput.invalidDate"),
      day: t("dateInput.day"),
      month: t("dateInput.month"),
      year: t("dateInput.year"),
      clearDate: t("dateInput.clearDate"),
      selectDate: t("dateInput.selectDate"),
      today: t("dateInput.today"),
      cancel: t("dateInput.cancel")
  }

  return <CompLibTranslations value={{dateInput: dateInputTranslations }}>{ children }</CompLibTranslations>
}