/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { useEffect, useMemo, useState } from "react";

import { Toast } from "@loyaltylogistix/component-library";

// Create an empty object for the toast to toggle it upon the
// user's actions
// eslint-disable-next-line import/no-mutable-exports
export let toast = {
  error: (msg) => null,
  success: (msg) => null,
  warning: (msg) => null
};

// eslint-disable-next-line react/prop-types
export function ToastContainer({ children, time }) {
  // Declare the initial state of the component
  const [isToast, setToast] = useState({});

  // Declare an array of types that the user shall user in order to
  // display an error/success/warning msg
  const types = useMemo(() => ["error", "success", "warning"], []);

  // Loop through the types and toggle the one that the user chooses
  // displaying the message the user desires
  types.forEach( type => {
    toast = {
      ...toast,
      [type]: (message) => {
        setToast({ type, toggle: true, message });
      },
    };
  })

  // Fire all actions below whenever the component renders
  useEffect(() => {
    // Check if the toast is toggled or not so that we can turn it
    // off after the timeout ends
    if (isToast.toggle) {
      setTimeout(() => {
        // Set the default timeout to 2500ms unless the user has a
        // different choice
        setToast({ ...isToast, toggle: false });
      }, time ?? 2500);
    }
    // Re-call the functions above in case on of 
    // the dependencies change
  }, [isToast, time]);

  return (
    <React.Fragment>
      <Toast
        toggle={isToast.toggle}
        message={isToast.message}
        type={isToast.type}
      />
      {children}
    </React.Fragment>
  );
}

export default ToastContainer;