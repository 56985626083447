import React, { ReactElement, useEffect } from "react";

import { Reactxios, SetupHook } from "reactxios";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import {
    IPublicClientApplication,
    InteractionRequiredAuthError,
    InteractionType,
} from "@azure/msal-browser";

import { Loader } from "@loyaltylogistix/component-library";
import useActiveAccount from "hooks/useActiveAccount";

import { toast } from "Components/ToastContainer";

import { useHistory } from "react-router-dom";
import { PlatformConfig } from "../types/Platform.types";
import { protectedResources } from "../utils/msalFactory";

export interface AxiosProviderProps {
    config: PlatformConfig;
    children: ReactElement;
}

export interface ReactxiosProps {
    url: string;
    tenant: string;
    scopes: string[];
    msal: IPublicClientApplication;
}

const AxiosSetup: SetupHook<ReactxiosProps> = (axios, { url, tenant, scopes, msal }) => {
    axios.defaults.baseURL = url;
    axios.interceptors.request.use(async (cfg) => {
        const accounts = msal.getAllAccounts();
        const account = accounts[0];
        msal.setActiveAccount(account);
        const token = await msal
            .acquireTokenSilent({
                scopes: [`https://${tenant}.onmicrosoft.com/api/access_as_user`],
                account: account || undefined,
            })
            .then((res) => res?.accessToken)
            .catch((err) => {
                if (err instanceof InteractionRequiredAuthError) {
                    if (account) {
                        msal.acquireTokenRedirect({
                            scopes,
                            account,
                        }).catch((tokenErr) =>
                            (toast as { error: (a: string) => void }).error(tokenErr?.message)
                        );
                    }
                }
            });

        cfg.headers = cfg?.headers || {};
        (cfg.headers as any).Accept = "application/json";
        (cfg.headers as any)["Content-Type"] = "application/json";
        (cfg.headers as any).Authorization = `Bearer ${token}`;

        return cfg;
    });
};

export default function AxiosProvider({ children, config }: AxiosProviderProps) {
    const { instance, accounts } = useMsal();
    const resources = protectedResources(config.api.b2c);

    const history = useHistory();

    const request = {
        scopes: resources.loginApi.scopes,
        state: window.location.pathname,
    };

    const { login, error, result } = useMsalAuthentication(InteractionType.Silent, request);

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) login(InteractionType.Redirect, request);
        instance.setActiveAccount(accounts?.[0]);
    }, [accounts, error]);

    useEffect(() => {
        if (result?.state) {
            history.replace(result.state);
        }
    }, [result?.state]);

    const { status, user } = useActiveAccount();
    const msalAccountIsInitialized = status === "success" && user !== null;

    return (
        <Reactxios<ReactxiosProps>
            url={config.api.url}
            tenant={config.api.b2c.TENANT_NAME}
            scopes={resources.loginApi.scopes}
            msal={instance}
            setup={AxiosSetup}
        >
            {!msalAccountIsInitialized ? (
                <Loader
                    color={undefined}
                    delay={undefined}
                />
            ) : (
                children
            )}
        </Reactxios>
    );
}
