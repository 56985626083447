import dayjs, { Dayjs } from "dayjs";
import quarter from "dayjs/plugin/quarterOfYear";
import dayOfYear from "dayjs/plugin/dayOfYear";

import { MappedArr, formatDayjsToUTC } from "global/tsHelpers";

dayjs.extend(quarter);
dayjs.extend(dayOfYear);

export interface ActionsObject {
    [id: string]: {
        label: string;
        selected?: boolean;
        isHighlighted?: boolean;
        handler: () => readonly [string, string];
        isDisabled?: boolean;
    };
}

export const formatDayjsArrToUTC = <TType extends readonly Dayjs[]>(arr: TType) =>
    arr.map(formatDayjsToUTC) as MappedArr<TType, Dayjs, string>;

export const yesterday = () => dayjs().subtract(1, "day").endOf("day");
export const lastMonth = () => dayjs().subtract(1, "month").startOf("month");

export const isStartOfMonth = () => {
    const today = dayjs();
    return today.date() === 1;
};

export const isFirstDayOfYear = () => {
    const today = dayjs();
    return today.dayOfYear() === 1;
};

export const isFirstDayOfQuarter = () => {
    const today = dayjs();
    const firstDayOfQuarter = today.startOf("quarter");
    return today.isSame(firstDayOfQuarter, "day");
};

export const lastFullMonth = () => {
    const today = dayjs();
    const startOfLastMonth = today.subtract(1, "month").startOf("month");
    const endOfLastMonth = today.subtract(1, "month").endOf("month");
    return formatDayjsArrToUTC([startOfLastMonth, endOfLastMonth] as const);
};

export const lastTwelveMonths = () => {
    const today = dayjs();
    const startOfLastTwelveFullMonths = today.subtract(12, "months").startOf("month");

    return formatDayjsArrToUTC([startOfLastTwelveFullMonths, yesterday()] as const);
};

export const monthToDate = () => {
    const today = dayjs();
    const startOfMonth = isStartOfMonth() ? lastMonth() : today.date(1).startOf("day");
    return isStartOfMonth()
        ? lastFullMonth()
        : formatDayjsArrToUTC([startOfMonth, yesterday()] as const);
};

export const lastFullQuarter = () => {
    const today = dayjs();
    const startOfLastFullQuarter = today.subtract(1, "quarter").startOf("quarter");
    const endOfLastFullQuarter = today.subtract(1, "quarter").endOf("quarter");
    return formatDayjsArrToUTC([startOfLastFullQuarter, endOfLastFullQuarter] as const);
};
export const quarterToDate = () => {
    const today = dayjs();
    const startOfQuarter = today.startOf("quarter");
    return isFirstDayOfQuarter()
        ? lastFullQuarter()
        : formatDayjsArrToUTC([startOfQuarter, yesterday()] as const);
};

export const yearToDate = () => {
    const today = dayjs();
    const startOfYear = today.startOf("year");

    return isFirstDayOfYear()
        ? lastTwelveMonths()
        : formatDayjsArrToUTC([startOfYear, yesterday()] as const);
};

export const lastTwelveMonthsFromLastMonth = () => {
    const today = dayjs();

    const startOfLastTwelveFullMonths = today.subtract(13, "months").startOf("month");
    const endOfLastTwelveFullMonths = today.subtract(1, "month").endOf("month");

    return formatDayjsArrToUTC([startOfLastTwelveFullMonths, endOfLastTwelveFullMonths] as const);
};

export const today = () => formatDayjsArrToUTC([dayjs().startOf("day"), dayjs()] as const);
