const navXYSize = "var(--headerDimensions, 55px)";
const navBorderWidth = "3px";
const transitionDuration = "250ms";
const mqMobile = "@media screen and (max-width: 675px)";

const anchorStyle = (theme) => ({
    // dimensions
    padding: "5px 15px",
    height: navXYSize,
    boxSizing: "border-box",
    position: "relative",

    // colours
    color: theme.colors.white,
    width: "100%",
    "::after": {
        content: "''",
        display: "block",
        position: "absolute",
        inset: 0,
        borderLeft: `${navBorderWidth} solid transparent`,
    },
    ":hover": {
        background: theme.colors.rollover,
    },
    ":focus": {
        background: theme.colors.rollover,
        outline: "2px solid white",
    },
    "&.active": {
        background: theme.colors.selected,
        "::after": {
            borderColor: theme.colors.white,
        },
    },

    // decoration
    textDecoration: "none",

    // layout
    display: "flex",
    flexFlow: "column nowrap",
    gap: "3px",
    alignItems: "center",
    justifyContent: "center",

    [mqMobile]: {
        margin: "auto",
        width: "100%",
        "::after": {
            borderLeft: "none",
        },
        "&.active": {
            background: theme.colors.positive,
        },
    },

    p: {
        fontSize: "9px",
        margin: "0",
        padding: "0",
        textAlign: "center",
    },

    // animation
    transition: `${transitionDuration} ease`,
});

export const wrapper = (theme) => ({
    // dimensions
    boxSizing: "borderBox",
    width: navXYSize,
    margin: 0,
    padding: 0,

    // typography
    fontSize: theme.fonts.xl,
    fontWeight: "500",

    // colours
    color: theme.colors.white,
    background: theme.colors.background,

    // layout
    gridColumn: "1 / 2",
    gridRow: "1 / -1",
    display: "flex",
    flexFlow: "column nowrap",

    // styling
    listStyle: "none",
    width: "100%",
    a: anchorStyle(theme),
    li: {
        width: "100%",

        [mqMobile]: {
            height: "100%",
            flexGrow: "1",
        },
    },

    [mqMobile]: {
        gridColumn: "1/-1",
        gridRow: "-1/-2",
        flexFlow: "row nowrap",
        justifyContent: "space-around",
        alignItems: "center",
        alignSelf: "flex-end",
        width: "100%",
        height: "fit-content",
        zIndex: 1,
        "li: first-of-type": {
            display: "none",
        },
    },

    img: {
        position: "relative",
        width: "100%",
    },
});

export const icon = (theme) => ({
    aspectRatio: "1",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.colors?.navBarIconBkg || "#202020",
    button: {
        background: "none",
        border: "none",
        padding: "0px",
        ":focus": {
            outline: "2px solid white",
        },
    },
    img: {
        padding: "7px",
        aspectRatio: "1",
        objectFit: "contain",
    },
});
