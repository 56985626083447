import React from "react";
import { t } from "i18next";
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { Loader } from "@loyaltylogistix/component-library";
import ErrorBoundary from "Components/ErrorBoundary/ErrorBoundary";
import PageTemplate from "Components/PageTemplate/PageTemplate";

// Check if the route has to be rendered within the page template
// or not
export const handleRouteDisplay = ({ template, title, navigation, component }) => {
    const transTitle = title.toLowerCase().replace(" ", "-");
    return template === "page-template" ? (
        <PageTemplate
            title={t(`pageTitles.${transTitle}`)}
            navigation={navigation}
        >
            <React.Suspense fallback={<Loader />}>
                <ErrorBoundary>{component}</ErrorBoundary>
            </React.Suspense>
        </PageTemplate>
    ) : (
        component
    );
};
