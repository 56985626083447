/* eslint-disable react/jsx-filename-extension */
import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import WebFont from "webfontloader";
import { BrowserRouter as Router } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import reportWebVitals from "./reportWebVitals";
import "./i18n";

import "bootstrap/dist/css/bootstrap.min.css";
// Load the Roboto font used all over the project
WebFont.load({
    google: {
        families: ["Roboto:300,400,500", "sans-serif"],
    },
});

const loader = document.getElementById("loader");
if (loader instanceof HTMLElement) loader.className = "hide";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Fatal: Root element not found");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <StrictMode>
        <Suspense fallback={null}>
            <Router>
                <AppWrapper />
            </Router>
        </Suspense>
    </StrictMode>
);

// If you want to start measuring performance in your app
// pass a function to log results
// (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
