export const headerUtilitiesWrapper = {
    listStyleType: "none",
    margin: 0,
    fontSize: "18px",
    padding: "10px",
    gap: "10px",
    display: "flex",
    alignItems: "center",
    zIndex: 12,
    "> li": {
        color: "#707070",
        display: "inline-block",
        cursor: "pointer",
        ":hover": {
            color: "black",
        },
    },
    ".marketSelectDropdown > div":{
        minWidth:"20ch",
        input:{
            width:"5ch"
        }
    }
};

export const searchIcon = {
    color: "red",
};
