import { useQuery } from "react-query";

import { FetchAppConfig } from "./FetchAppConfig";
import useDomain from "./useDomain";
import DefaultAppConfig from "../defaults/Platform.defaults";

const FallbackConfigData = {
  theme: {},
  config: DefaultAppConfig
}

export default function useInitialAppData(){
  const domain = useDomain();

  const { data: { theme, config } = FallbackConfigData, isLoading } = useQuery(
    ["config", domain],
    ({ queryKey: [_, currentDomain] }) => FetchAppConfig(currentDomain),
  )

  return {
    isLoading,
    theme,
    config,
    domain,
  }
}