/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useEffect, useMemo } from "react";

import { Loader } from "@loyaltylogistix/component-library";
import { useMsal } from "@azure/msal-react";
import { loginRequest as loginRequestFactory } from "state/global/core/utils/msalFactory"
import { useRecoilValue } from "recoil";
import { PlatformConfig } from "state/global";

function RedirectToLogin() {
    const config = useRecoilValue(PlatformConfig);
    const loginRequest = useMemo(() => loginRequestFactory(config.api.b2c), [config]);
    const { instance, inProgress } = useMsal();

    const handleLogin = () => instance.loginRedirect(loginRequest);

    useEffect(() => {
        if (inProgress === "none") handleLogin();
    }, []);

    return <Loader />;
}

export default RedirectToLogin;
