// a format function to format dates and numbers within i18n
// example : in translation json file use it as "today": "Today is {{today, date}}" and in component: {t("today", {today: new Date() })} and the result would be
// or if you want to use another format would be : "today": "Today is — {{date, date(day: numeric; month: short; year: numeric)}}" => "Nov 14, 2022"
// for more info => https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#Syntax

function toJsonString(formatType, specifier) {
    const inner = formatType
        .trim()
        .replace(specifier, "")
        .replace("(", "")
        .replace(")", "")
        .split(";")
        .map((param) =>
            param
                .split(":")
                .map((name) => `"${name.trim()}"`)
                .join(":")
        )
        .join(",");

    return `{${inner}}`;
}
function toOptions(formatType, specifier) {
    if (formatType.trim() === specifier) {
        return {};
    }

    try {
        return JSON.parse(toJsonString(formatType, specifier));
    } catch (error) {
        console.error(error);

        return null;
    }
}
function formatDate(value, formatType, lng) {
    const options = toOptions(formatType, "date");

    return options === null ? value : new Intl.DateTimeFormat(lng, options).format(value);
}

// this to format number with/without unit.
// example without unit: "recipientsCount": "{{count, number}} recipients"=>{t("recipientsCount", { count: 500 })}

// example with units: "milesInKm": "{{mile, number(style: unit; unit: kilometer)}}" => {t("milesInKm", {mile: theMilesValueHere})}

function formatNumber(value, formatType, lng) {
    const options = toOptions(formatType, "number");

    return options === null ? value : new Intl.NumberFormat(lng, options).format(value);
}
function format(value, formatType, lng) {
    if (format.startsWith("date")) {
        return formatDate(value, formatType, lng);
    }

    if (format.startsWith("number")) {
        return formatNumber(value, formatType, lng);
    }

    return value;
}

export default format;
