import { DefaultValue, selector } from "recoil";
import { MarketModel } from "@loyaltylogistix/api-client";

import { CorePlatformActiveMarket, CorePlatformAvailableMarkets } from "../core/atoms";

export interface PlatformMarketsState{
  isLoading: boolean;
  available: MarketModel[];
  active: MarketModel;
}

export const PlatformMarkets = selector<PlatformMarketsState>({
  key: "public::platform-markets",
  get: ({ get }) => {
    const { isLoading, available } = get(CorePlatformAvailableMarkets);
    const active = get(CorePlatformActiveMarket);

    return {
      isLoading,
      available,
      active
    }
  }
})

export const PlatformActiveMarket = selector<MarketModel>({
  key: "public::platform-active-market",
  get: ({ get }) => get(CorePlatformActiveMarket),
  set: ({ set, get }, newValue) => {
    const{ available } = get(CorePlatformAvailableMarkets);
    if (newValue instanceof DefaultValue){
      set(CorePlatformActiveMarket, available[0]);
      return;
    }
    const newMarket = available.find((m) => m.id === newValue.id) || available[0];
    set(CorePlatformActiveMarket, newMarket);
  }
})

export const PlatformAvailableMarkets = selector({
  key: "public::platform-available-markets",
  get: ({ get }) => get(CorePlatformAvailableMarkets)
})