import { DefaultValue, selector } from "recoil";
import { CorePlatformDealers } from "../core/atoms";

export const PlatformDealers = selector({
    key: "public::platform-dealers",
    get: ({ get }) => get(CorePlatformDealers),
});

export const PlatformDealerAreas = selector({
    key: "public::platform-dealer-areas",
    get: ({ get }) => get(CorePlatformDealers).areas,
});

export const PlatformDealerGroups = selector({
    key: "public::platform-dealer-groups",
    get: ({ get }) => get(CorePlatformDealers).groups,
});

export const PlatformDealerSizes = selector({
    key: "public::platform-dealer-sizes",
    get: ({ get }) => get(CorePlatformDealers).sizes,
});

export const PlatformRetailerSites = selector({
    key: "public::retailer-sites",
    get: ({ get }) =>
        get(CorePlatformDealers).areas.flatMap(
            (area) => (area.retailerSites || [])?.map((site) => ({ ...site, area })) || []
        ),
});

export const PlatformRetailerSiteIds = selector({
    key: "public::retailer-site-ids",
    get: ({ get }) => get(PlatformRetailerSites).map((site) => site.retailerSiteId as string),
});

export const PlatformSelectedDealers = selector({
    key: "public::platform-selected-dealers",
    get: ({ get }) => get(CorePlatformDealers).selected,
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformDealers, (prev) => ({ ...prev, selected: [] }));
            return;
        }
        set(CorePlatformDealers, (prev) => ({ ...prev, selected: newValue }));
    },
});
