// Customize the styling of the error boundary wrapper
export const errorBoundary = {
  height: "100%",
  h1: {
    fontWeight: "bolder",
  },
  button: {
    width: "fit-content",
    fontWeight: "bolder",
    marginBlock: "10px",
  },
  img: {
    width: "100%",
    height: "100%",
  },
};
