import { atom } from "recoil";

import type { PlatformConfig } from "../types/Platform.types";
import DefaultAppConfig from "../defaults/Platform.defaults";

/**
 * @protected This atom should not be accessed directly. Use PlatformConfig selector instead.
 */
export const CorePlatformConfig = atom<PlatformConfig>({
  key:"core::platform-config",
  default: DefaultAppConfig
})