/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Children, Fragment } from "react";

import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NavMenuLink({
    isActive,
    to,
    label,
    faIcon,
    children
}){
    return <li>
        <NavLink
            isActive={isActive}
            to={to}
            aria-label={label}>
            { Children?.count(children) > 0 
                ? children 
                : <Fragment>
                    <FontAwesomeIcon icon={faIcon} fixedWidth />
                     <p>{label}</p>
                </Fragment>
            }
        </NavLink>
    </li>
}