/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import HeaderUtilities from "../HeaderUtilities/HeaderUtilities";

import * as styles from "./ModuleHeader.styles";

function ModuleHeader({ title }) {
    return (
        <div css={styles.moduleWrapper}>
            <h1>{title}</h1>
            <HeaderUtilities />
        </div>
    );
}

ModuleHeader.propTypes = {
    title: PropTypes.string,
};

export default ModuleHeader;
