/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import { Button, Image, Row } from "@loyaltylogistix/component-library";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";

import { icon_error } from "global/images";
import * as styles from "./ErrorBoundary.styles";

// Create an instance for the browser history so that it can be used to get the
// user out of the error page
const history = createBrowserHistory();

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        // Use object destructuring to get some props from the state/props
        const { hasError } = this.state;
        const { children } = this.props;
        // Check if there is an error during runtime
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <Row
                    className="d-flex flex-column align-items-center justify-content-center text-center p-3"
                    css={styles.errorBoundary}
                >
                    <h1>OH NO!</h1>
                    <h5>Something went wrong, please try again later</h5>
                    <Button
                        label="GO BACK"
                        onClick={() => history.back()}
                    />
                    <Image src={icon_error} />
                </Row>
            );
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
};

export default ErrorBoundary;
