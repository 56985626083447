import { PlatformConfig, PlatformTheme } from "../types/Platform.types";
import DefaultAppConfig from "../defaults/Platform.defaults";

export interface ConfigFile{
  uri?: string;
}

export async function FetchConfigFile<ConfigType extends ConfigFile>(uri: string, key: string, fallback: ConfigType) : Promise<ConfigType> {
  try{
    const confFile = JSON.parse(window.localStorage.getItem(key) || "");
    if (confFile.uri !== uri){
      throw new Error("Invalid config domain -attempt load");
    }
    return confFile as ConfigType;
  } catch(err){
    const confFile = await fetch(uri)
      .then( res => res.json() )
      .then( data => {
        window.localStorage.setItem(key, JSON.stringify({uri, ...data}));
        return data as ConfigType;
      })
      .catch( () => fallback)
    return confFile;
  }
}

export async function FetchAppThemeFile(domain: string) {
  return FetchConfigFile<PlatformTheme | Record<string, unknown>>(domain, "llplatform-theme", {});
}

export async function FetchAppConfigFile(domain: string){
  return FetchConfigFile<PlatformConfig>(domain, "llplatform-config", DefaultAppConfig);
}