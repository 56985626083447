import { DefaultValue, selector } from "recoil";
import { CoreCampaignsDealers } from "../core/atoms";

export const CampaignsDealers = selector({
    key: "public::campaigns-dealers",
    get: ({ get }) => get(CoreCampaignsDealers),
});

export const CampaignsDealerAreas = selector({
    key: "public::campaigns-dealer-areas",
    get: ({ get }) => get(CoreCampaignsDealers).areas,
});

export const CampaignsDealerGroups = selector({
    key: "public::campaigns-dealer-groups",
    get: ({ get }) => get(CoreCampaignsDealers).groups,
});

export const CampaignsSelectedDealers = selector({
    key: "public::campaigns-selected-dealers",
    get: ({ get }) => get(CoreCampaignsDealers).selected,
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CoreCampaignsDealers, (prev) => ({ ...prev, selected: [] }));
            return;
        }
        set(CoreCampaignsDealers, (prev) => ({ ...prev, selected: newValue }));
    },
});
