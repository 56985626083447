import { useLayoutEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";

export function Head( { 
  title = "Renault CRM", 
  favicon = "/icons/logo.svg",
  children
}) {

  // useLocation router-dom to read pathname
  // so that the page title can be set
  // accordingly
  const location = useLocation();

  // get the head <link> element so that the href can
  // be updated programmatically - ideally we'd want to
  // set the icon in a pre-connect script

  const getFaviconEl = ()=>document.getElementById("favicon");
  const formatTitle = (locationItems) => 
    `${title} | ${locationItems.join(" > ")}`;

  useLayoutEffect( () => {

    // split the pathname and correct the casing
    let pathTitles;
    try{
      pathTitles = location.pathname
        .split("/")
        .slice(1)
        .map(item => item[0].toLocaleUpperCase() + item.slice(1));
    }catch(err){
      pathTitles = ["Login"];
    }
    
    // set the doc title
    document.title = formatTitle(pathTitles);

    // set the favicon to the clients favicon
    const faviconElm = getFaviconEl();
    faviconElm.href  = favicon;

  }, [ title, favicon, location.pathname ]);

  return children;
  
}

Head.defaultProps = {
  title: "Renault CRM",
  favicon: "/icons/renault_logo.svg",
}

Head.propTypes = {
  title: PropTypes.string,
  favicon: PropTypes.string,
}

export default Head;