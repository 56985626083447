/** @jsxRuntime classic */
/** @jsx jsx */
import { Global, jsx } from "@emotion/react";
import { Fragment } from "react";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import { Redirect, Route, Router, useHistory } from "react-router-dom";

import { Loader } from "@loyaltylogistix/component-library";

import { useTranslation } from "react-i18next";
import { useRoutes } from "hooks/useRoutes";
import ModuleMenu from "./Components/ModuleMenu/ModuleMenu";
import RedirectToLogin from "./Containers/Login/RedirectToLogin";
import ProtectedRoute from "./Containers/Login/ProtectedRoute";

import * as styles from "./styles/App.styles";

function App() {
    /**
     * useMsal is hook that returns the PublicClientApplication instance
     * an array of all accounts currently signed in and an inProgress
     * value that tells you what msal is currently doing.
     * For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { inProgress } = useMsal();
    const { ready } = useTranslation("");

    // Fetch all routes from the useRoutes custom hook
    const [allRoutes, , mainPermittedRoutes] = useRoutes();
    // Getting the browser history
    const history = useHistory();

    return inProgress !== "none" ? (
        <Loader
            color={undefined}
            delay={undefined}
        />
    ) : (
        <Fragment>
            <AuthenticatedTemplate>
                <Global
                    styles={(theme) => ({
                        table: {
                            thead: {
                                th: {
                                    ...theme.future.typography.headings[5],
                                    button: {
                                        ...theme.future.typography.headings[5],
                                    },
                                },
                            },
                        },
                    })}
                />
                <Router history={history}>
                    <Route
                        exact
                        path="/"
                    >
                        <Redirect
                            to={
                                (mainPermittedRoutes[0 as keyof typeof mainPermittedRoutes] as any)
                                    ?.path
                            }
                        />
                    </Route>
                    {ready && (
                        <div css={styles.wrapper}>
                            <ModuleMenu />
                            {(allRoutes as any[])?.map((route) => (
                                <Fragment key={route.path}>
                                    <ProtectedRoute route={route} />
                                </Fragment>
                            ))}
                        </div>
                    )}
                </Router>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <RedirectToLogin />
            </UnauthenticatedTemplate>
        </Fragment>
    );
}

export default App;
