import { useMemo } from "react";

export default function useDomain(){
  const domain = useMemo( () => {
    if (process.env.NODE_ENV !== "production"){
      const dmn = window.localStorage.getItem("dev-ovr-web-dmn");
      if (dmn) return dmn;
    }
    return window.location.hostname;
  }, []);

  return domain;
}